import React, { useState, useEffect } from "react";
import * as Tone from "tone";
import "./App.css";
import { Cell } from "./Cell";
import { CellTone } from "./CellTone";

function App() {
  const [base, setBase] = useState(45);
  const [maj, setMaj] = useState(false);
  const [audioContext, setAudioContext] = useState(false);
  const [numTones, setNumTones] = useState(0);

  useEffect(() => {
    const audioContext = new Tone.Context();
    Tone.setContext(audioContext);
    audioContext.resume();

    // Clean up the audio context when the component unmounts
    return () => {
      audioContext.close();
    };
  }, []);

  const handleStartAudio = async () => {
    await Tone.start();
    setAudioContext(true);
  };

  return (
    <div className="App" onClick={audioContext ? null : handleStartAudio}>
      <table>
        <tbody>
          <tr>
            <th>
              <Toggle set={setMaj} current={maj} />
            </th>
            <th>Ratio</th>
            <th>Base</th>
            <th>2nd Oct</th>
            <th>3rd Oct</th>
            <th>4th Oct</th>
            <th>5th Oct</th>
            <th>6th Oct</th>
            <th>7th Oct</th>
            <th>8th Oct</th>
          </tr>
          <tr>
            <th>I</th>
            <th>1/1</th>
            <td>
              <div className="cell">
                <CellTone
                  freq={base}
                  numTones={numTones}
                  setNumTones={setNumTones}
                />
                <input
                  id="baseInput"
                  defaultValue={45}
                  onKeyPress={(e) => {
                    if (!/[0-9.]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => setBase(e.target.value)}
                />
              </div>
            </td>
            <td>
              <Cell
                base={base}
                octave={1}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={2}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={3}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={4}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={5}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={6}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
            <td>
              <Cell
                base={base}
                octave={7}
                ratio={0}
                numTones={numTones}
                setNumTones={setNumTones}
              />
            </td>
          </tr>
          {maj ? (
            <Row
              row={"II"}
              int={"9/8"}
              base={base}
              ratio={2}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          ) : (
            <Row
              row={"II"}
              int={"16/15"}
              base={base}
              ratio={1}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          )}
          {maj ? (
            <Row
              row={"III"}
              int={"5/4"}
              base={base}
              ratio={4}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          ) : (
            <Row
              row={"III"}
              int={"6/5"}
              base={base}
              ratio={3}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          )}
          <Row
            row={"IV"}
            int={"4/3"}
            base={base}
            ratio={5}
            numTones={numTones}
            setNumTones={setNumTones}
          />
          <Row
            row={"Tritone"}
            int={"45/32"}
            base={base}
            ratio={6}
            numTones={numTones}
            setNumTones={setNumTones}
          />
          <Row
            row={"V"}
            int={"3/2"}
            base={base}
            ratio={7}
            numTones={numTones}
            setNumTones={setNumTones}
          />
          {maj ? (
            <Row
              row={"VI"}
              int={"5/3"}
              base={base}
              ratio={9}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          ) : (
            <Row
              row={"VI"}
              int={"8/5"}
              base={base}
              ratio={8}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          )}
          {maj ? (
            <Row
              row={"VII"}
              int={"15/8"}
              base={base}
              ratio={11}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          ) : (
            <Row
              row={"VII"}
              int={"9/5"}
              base={base}
              ratio={10}
              numTones={numTones}
              setNumTones={setNumTones}
            />
          )}
        </tbody>
      </table>
    </div>
  );
}

function Toggle(props) {
  return (
    <div className="toggleWrap">
      <div
        className={`toggleButton ${props.current ? "" : "selected"}`}
        onClick={(e) => {
          props.set(false);
        }}
      >
        Min
      </div>
      <div
        className={`toggleButton ${props.current ? "selected" : ""}`}
        onClick={(e) => {
          props.set(true);
        }}
      >
        Maj
      </div>
    </div>
  );
}

function Row(props) {
  return (
    <tr>
      <th>{props.row}</th>
      <th>{props.int}</th>
      <td>
        <Cell
          base={props.base}
          octave={0}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={1}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={2}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={3}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={4}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={5}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={6}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
      <td>
        <Cell
          base={props.base}
          octave={7}
          ratio={props.ratio}
          numTones={props.numTones}
          setNumTones={props.setNumTones}
        />
      </td>
    </tr>
  );
}

export default App;
