import { CellTone } from "./CellTone";

let ratios = [
  1 / 1,
  16 / 15,
  9 / 8,
  6 / 5,
  5 / 4,
  4 / 3,
  45 / 32,
  3 / 2,
  8 / 5,
  5 / 3,
  9 / 5,
  15 / 8,
  2 / 1,
];
// 1.00000: Unison       (1/1)
// 1.06667: minor 2nd    (16/15)
// 1.12500: Major 2nd    (9/8)
// 1.20000: minor 3rd    (6/5)
// 1.25000: Major 3rd    (5/4)
// 1.33333: Perfect 4th  (4/3)
// 1.40625: Tritone      (45/32)
// 1.50000: Perfect 5th  (3/2)
// 1.60000: minor 6th    (8/5)
// 1.66667: Major 6th    (5/3)
// 1.80000: minor 7th    (9/5)
// 1.87500: Major 7th    (15/8)
// 2.00000: Octave       (2/1)

export function Cell(props) {
  let val = props.base * 2 ** props.octave * ratios[props.ratio];
  return (
    <div className="cell">
      <CellTone
        freq={val}
        on={false}
        numTones={props.numTones}
        setNumTones={props.setNumTones}
        audioContext={props.audioContext}
      />
      {Number(val.toFixed(3))}
    </div>
  );
}
