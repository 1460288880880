import React, { useState, useEffect } from "react";
import * as Tone from "tone";

export function CellTone(props) {
  const [cellOn, setCellOn] = useState(false);

  useEffect(() => {
    const osc = new Tone.Oscillator(props.freq, "sine");
    const gain = new Tone.Gain(0);

    osc.connect(gain);
    gain.toDestination();
    gain.gain.rampTo(cellOn ? 0.7 / props.numTones : 0, 0.25);
    osc.start();

    return () => {
      gain.gain.rampTo(0, 0.25);
      osc.stop("+0.5");
      setTimeout(() => {
        osc.dispose();
        gain.dispose();
      }, 500);
    };
  }, [cellOn, props.numTones, props.freq]);

  return (
    <div className="cell-tone">
      <input
        type="checkbox"
        className="tone-toggle"
        onChange={(c) => {
          setCellOn(c.target.checked);
          props.setNumTones(props.numTones + (c.target.checked ? 1 : -1));
        }}
      />
    </div>
  );
}
